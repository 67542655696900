import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import s from './App.module.scss';
import Landing from './pages/Landing/Landing';


function App() {
  return (
    <Router>
      <div className={s.appContainer}>
        <main className={s.mainContent}>
          <Routes>          
            <Route path="/" element={<Landing />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
