import React from 'react';
import { FaTwitter, FaComments, FaDesktop, FaBell } from 'react-icons/fa';
import s from './Landing.module.scss';

const Landing = () => {
  return (
    <div className={s.landingContainer}>
    <div className={s.landingIntroContainer}>
      <header className={s.landingHeader}>
        <div className={s.landingLogo}>Cask AI</div>
        <div className={s.landingButtonContainer}>
          <button className={`${s.landingButton} ${s.landingLoginButton}`}>Login</button>
          <button className={`${s.landingButton} ${s.landingSignupButton}`}>Sign Up</button>
        </div>
      </header>
      <main>
        <h1 className={s.landingHeadline}>Your Public Affairs Agent</h1>
        <p className={s.landingSubheadline}>
          Harnessing the power of AI to supercharge Political analysis.            
        </p>
        <div className={s.landingFeatureBoxes}>
          <div className={s.landingFeatureBox}>
            <div className={s.landingFeatureIcon}>
              <FaComments />
            </div>
            <h2 className={s.landingFeatureTitle}>Chat & Search</h2>
            <p className={s.landingFeatureDescription}>Use natural language to chat to our system and get the answers and insights you need.</p>
          </div>
          <div className={`${s.landingFeatureBox} ${s.landingFeatureBoxMiddle}`}>
            <div className={s.landingFeatureIcon}>
              <FaDesktop />
            </div>
            <h2 className={s.landingFeatureTitle}>Monitor & Reporting</h2>
            <p className={s.landingFeatureDescription}>Set up monitoring of across the entire Scottish Political landscape. Generate comprehensive reports for any political events or anaylsis you need.</p>
            <button className={s.landingFeatureSignupButton}>Get Started Now</button>
          </div>
          <div className={s.landingFeatureBox}>
            <div className={s.landingFeatureIcon}>
              <FaBell />
            </div>
            <h2 className={s.landingFeatureTitle}>Real Time Alerts</h2>
            <p className={s.landingFeatureDescription}>Get notified as soon as new legislation is published.</p>
          </div>
        </div>
      </main>
      <hr className={s.landingDivider} />
      </div>
      <div className={s.landingFeatureContainer}>
      <div className={s.fullWidthSection}>
        <section className={s.detailedFeatures}>
          <div className={`${s.featureSection} ${s.imageLeft}`}>
            <div className={s.featureSectionInner}>
              <div className={s.featureImage}>
                <img src="/images/chat-search.jpg" alt="Chat & Search" />
              </div>
              <div className={s.featureContent}>
                <h2>Chat & Search</h2>
                <p>Our advanced AI-powered chat system allows you to ask complex questions about Scottish politics and receive accurate, up-to-date answers. Search through vast amounts of political data with ease, and get the insights you need in seconds.</p>
              </div>
            </div>
          </div>

          <div className={`${s.featureSection} ${s.imageRight}`}>
            <div className={s.featureSectionInner}>
              <div className={s.featureContent}>
                <h2>Monitor & Reporting</h2>
                <p>Stay on top of the entire Scottish political landscape with our comprehensive monitoring tools. Generate detailed reports on any political event or topic, tailored to your specific needs. Our AI analyzes trends and provides valuable insights to keep you informed.</p>
              </div>
              <div className={s.featureImage}>
                <img src="/images/monitor-reporting.jpg" alt="Monitor & Reporting" />
              </div>
            </div>
          </div>

          <div className={`${s.featureSection} ${s.imageLeft}`}>
            <div className={s.featureSectionInner}>
              <div className={s.featureImage}>
                <img src="/images/real-time-alerts.jpg" alt="Real Time Alerts" />
              </div>
              <div className={s.featureContent}>
                <h2>Real Time Alerts</h2>
                <p>Never miss important political developments with our real-time alert system. Get instant notifications when new legislation is published, key votes occur, or significant political events unfold. Customize your alerts to focus on the topics that matter most to you.</p>
              </div>
            </div>
          </div>
        </section>
      </div>
      </div>
      <FaTwitter className={s.landingTwitterIcon} />
      
    </div>
  );
};

export default Landing;
